import React from "react";
import Layout from "../../components/Layout";
import CaseStudiesContainer from "../../components/CaseStudies";

const homeData = {
  "@context": "http://schema.org",
  "@type": "WebSite",
  mainEntity: {
    "@type": "Organization",
    "@id": "https://bidddy.com/",
    name: "Bidddy Case Studies",
    url: "https://bidddy.com/",
    foundingDate: "2021",
    logo: {
      "@type": "ImageObject",
      url: "https://imgix.cosmicjs.com/e7b63c80-de97-11eb-a12b-17ba28648e41-BidddyLogo.png",
    },
    description:
      "Bidddy is a tool that allows you to run comment driven auctions on Instagram. This provides businesses of any size a new way to grow sales and online presence.",
    sameAs: [
      "https://www.linkedin.com/company/bidddy/",
      "https://www.facebook.com/getbidddy/",
      "https://twitter.com/getbidddy",
    ],
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "Bidddy Team",
      email: "hello@bidddy.com",
      url: "https://bidddy.com/",
    },
  },
};

const CaseStudies = () => (
  <Layout
    hideFooter
    structured_data={homeData}
    showHomeFAQ
    image="https://imgix.cosmicjs.com/1ea993f0-de97-11eb-a12b-17ba28648e41-HomeOgImage.png"
    description="Fundraising and marketing case studies from using Bidddy."
    title="Case Studies | Bidddy"
    canonical="https://bidddy.com/case-studies/"
  >
    <CaseStudiesContainer />
  </Layout>
);

export default CaseStudies;
