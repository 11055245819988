import React from "react";
import cn from "classnames";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import styles from "../styles/index.module.scss";
import ArrowIcon from "../../Icons/ArrowIcon";
import DefaultButton from "../../Buttons/DefaultButton";

const GermanRedCross =
  "https://imgix.cosmicjs.com/8195af50-9cf3-11ed-93ee-cb9a2cd68754-RedCross.png";
const Kimono =
  "https://imgix.cosmicjs.com/6e0aca50-b577-11ed-ac22-eb594d52cfd7-Kimono.png";
const StoneCrop =
  "https://imgix.cosmicjs.com/56c70b80-b890-11ed-95c6-6595384cb1db-StoneCrop-1.png";
const RedCross =
  "https://imgix.cosmicjs.com/8f6378f0-b577-11ed-ac22-eb594d52cfd7-GermanRedCross.png";
const CalgaryHumane =
  "https://imgix.cosmicjs.com/8e696050-9cf3-11ed-93ee-cb9a2cd68754-CalgaryHumaneSociety.png";

const CollabSection = () => {
  const handleTryBidddy = () => {
    if (typeof window !== "undefined") {
      window.open("https://www.jotform.com/form/230056389150049", "_blank");
    }
  };
  return (
    <section
      className="w-full bg-white mb-0 md:pb-32 py-16"
      id="small-business"
    >
      <div className="max-w-6xl mx-auto px-5">
        <div className="w-full flex-col lg:flex-row lg:flex-wrap flex justify-start h-full">
          <div className="lg:w-1/2 w-full">
            <h3 className="font-roboto font-bold uppercase text-bidddyPink text-lg mb-2 md:pt-24">
              Collab Auctions
            </h3>
            <h2 className="font-inter text-6xl font-bold text-bidddyAltGray mb-6 md:w-1/5">
              Small business. Big support.
            </h2>
            <h4 className="uppercase font-cubano text-bidddyGray text-2xl leading-7 mb-3">
              Two Is Better than one
            </h4>
            <div className={styles.caseStudyPoints}>
              <h5>Intro</h5>
              <p>
                Nothing makes us prouder than helping small businesses support
                their favourite local cause. Bidddy provides businesses and non
                profits with a unique opportunity to help each other using
                shared auction posts.
              </p>
              <h5>Goals</h5>
              <p>Business</p>
              <ul>
                <li>Support favourite local cause</li>
                <li>Increase product and brand awareness</li>
                <li>Increase and engage customers</li>
                <li>Build brand reputation </li>
              </ul>
              <p>Non Profit</p>
              <ul>
                <li>Raise funds</li>
                <li>Increase event and cause awareness</li>
                <li>Increase social following and engagement</li>
                <li>Attract and engage supporters</li>
              </ul>
              <h5>Solution</h5>
              <p>
                Bidddy helps small businesses easily set up and manage
                fundraising auctions with a full set of services including:
              </p>
              <ul>
                <li>Automated auction integration for Instagram</li>
                <li>Personal auction management dashboard</li>
                <li>Advanced auction insights</li>
                <li>Dedicated support</li>
              </ul>
            </div>
          </div>
          <div className="lg:w-1/2 hidden lg:flex w-full flex-col justify-center items-center pt-64">
            <Imgix
              src={Kimono}
              height={789}
              width={408}
              htmlAttributes={{
                alt: "Nanao Kimono and Calgary Humane Society",
                src: `${Kimono}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className={cn(styles.image, "lazyload blur-up")}
            />
            <p className="w-1/2 text-center my-8">
              Japanese goods retailer Nanao Kimono raised $160 to support the
              Calgary Humane Society.
            </p>
            <Imgix
              src={`${CalgaryHumane}?auto=format`}
              height={70}
              width={120}
              htmlAttributes={{
                alt: "Calgary Humane Society",
                src: `${CalgaryHumane}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </div>
          <div
            className="lg:w-1/2 hidden lg:flex w-full flex-col justify-center items-center"
            style={{ marginTop: "-400px" }}
          >
            <Imgix
              src={StoneCrop}
              height={789}
              width={408}
              htmlAttributes={{
                alt: "StoneCrop and Unicef",
                src: `${StoneCrop}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className={cn(styles.image, "lazyload blur-up")}
            />
            <p className="w-1/2 text-center my-8">
              Artist StoneCrop Studio’s social auction raised $250 to support
              Unicef’s #giveavax campaign.
            </p>
          </div>
          <div className="lg:w-1/2 w-full hidden lg:flex flex-col justify-center items-center mt-32 mb-8">
            <Imgix
              src={RedCross}
              height={789}
              width={408}
              htmlAttributes={{
                alt: "Namokimods and German Red Cross",
                src: `${RedCross}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className={cn(styles.image, "lazyload blur-up")}
            />
            <p className="w-1/2 text-center my-8">
              @Namokimods partnered with @seikomods to raise $980 for the German
              Red Cross.
            </p>
            <Imgix
              src={`${GermanRedCross}?auto=format`}
              height={53}
              width={138}
              htmlAttributes={{
                alt: "German Red Cross",
                src: `${GermanRedCross}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </div>
        </div>
        <div className="w-full flex flex-row overflow-auto gap-4 lg:hidden">
          <div
            className="flex flex-col justify-center items-center"
            style={{ minWidth: "66vw" }}
          >
            <Imgix
              src={Kimono}
              height={789}
              width={408}
              htmlAttributes={{
                alt: "Nanao Kimono and Calgary Humane Society",
                src: `${Kimono}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className={cn(styles.image, "lazyload blur-up")}
            />
            <p className="w-full text-center my-8">
              Japanese goods retailer Nanao Kimono raised $160 to support the
              Calgary Humane Society.
            </p>
          </div>
          <div
            className="flex w-full flex-col justify-center items-center"
            style={{ minWidth: "66vw" }}
          >
            <Imgix
              src={StoneCrop}
              height={789}
              width={408}
              htmlAttributes={{
                alt: "StoneCrop and Unicef",
                src: `${StoneCrop}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className={cn(styles.image, "lazyload blur-up")}
            />
            <p className="w-full text-center my-8">
              Artist StoneCrop Studio’s social auction raised $250 to support
              Unicef’s #giveavax campaign.
            </p>
          </div>
          <div
            className="w-full flex flex-col justify-center items-center"
            style={{ minWidth: "66vw" }}
          >
            <Imgix
              src={RedCross}
              height={789}
              width={408}
              htmlAttributes={{
                alt: "Namokimods and German Red Cross",
                src: `${RedCross}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className={cn(styles.image, "lazyload blur-up")}
            />
            <p className="w-full text-center my-8">
              @Namokimods partnered with @seikomods to raise $980 for the German
              Red Cross.
            </p>
          </div>
        </div>
        <ArrowIcon className={styles.arrowRight} />
        <div className="flex md:justify-center md:items-center flex-col">
          <h3 className="mt-12 md:text-center md:mx-auto text-bidddyPink text-2xl font-bold font-inter">
            Ready to support your favourite cause?
          </h3>
          <DefaultButton
            extraClasses={styles.defaultButton}
            onClick={() => handleTryBidddy()}
            title="Create an Auction Event"
          />
        </div>
      </div>
    </section>
  );
};

export default CollabSection;
