import React from "react";
import cn from "classnames";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import styles from "../styles/index.module.scss";
import DefaultButton from "../../Buttons/DefaultButton";

const ThriveImage =
  "https://imgix.cosmicjs.com/11cbd170-b573-11ed-ac22-eb594d52cfd7-Thrive.png";

const TorontoThrive = () => {
  const handleTryBidddy = () => {
    if (typeof window !== "undefined") {
      window.open("https://www.jotform.com/form/230056389150049", "_blank");
    }
  };
  return (
    <section
      id="toronto-thrive"
      className="w-full mb-0 md:pb-32 py-16"
      style={{ background: "#F7F7F7" }}
    >
      <div className="max-w-6xl w-full mx-auto px-5">
        <div className="w-full flex-col lg:flex-row flex justify-start h-full">
          <div className="lg:w-2/3 w-full mb-8">
            <h3 className="block font-bold lg:hidden font-roboto uppercase text-bidddyPink text-lg mb-2 md:pt-24">
              Toronto Thrive
            </h3>
            <Imgix
              src={ThriveImage}
              height={717}
              width={717}
              htmlAttributes={{
                alt: "Toronto Thrive",
                src: `${ThriveImage}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className={cn(styles.image, "lazyload blur-up")}
            />
            <div className={styles.quote}>
              <h3>
                “Bidddy brought in a different level of engagement that I don’t
                think we had before this event.”
              </h3>
              <p>- Manhar, Co-founder </p>
            </div>
          </div>
          <div className="lg:w-1/3 w-full">
            <h3 className="hidden font-bold lg:block font-roboto uppercase text-bidddyPink text-lg mb-2 md:pt-24">
              Toronto Thrive
            </h3>
            <h2 className="font-inter text-6xl font-bold text-bidddyAltGray mb-6 md:w-1/5">
              Building Artist Community
            </h2>
            <a
              href="https://www.instagram.com/torontothrive/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <h4 className="uppercase font-cubano text-bidddyGray text-2xl leading-7 mb-3">
                @torontothrive
              </h4>
            </a>
            <div className={styles.caseStudyPoints}>
              <h5>Intro</h5>
              <p>
                Toronto Thrive is an organization that helps promote and support
                the local artist community. Using Bidddy, @torontothrive ran a
                social auction as a new and creative way to expand their gallery
                showcase event.
              </p>
              <h5>Goals</h5>
              <ul>
                <li>Promote local artists</li>
                <li>Increase event and org awareness</li>
                <li>Increase social following and engagement</li>
                <li>Attract and retain members</li>
              </ul>
              <h5>Solutions</h5>
              <p>
                Bidddy helped Toronto Thrive transition into the world of social
                auctions with a full set of solutions including:
              </p>
              <ul>
                <li>Automated auction integration for Instagram</li>
                <li>Custom branded auction page</li>
                <li>In-person + online auction support</li>
                <li>Advanced auction insights</li>
                <li>Dedicated 1-on-1 support</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex md:justify-center md:items-center flex-col">
          <h3 className="mt-12 md:text-center md:mx-auto text-bidddyPink text-2xl font-bold font-inter">
            Ready to better support your community?
          </h3>
          <DefaultButton
            extraClasses={styles.defaultButton}
            onClick={() => handleTryBidddy()}
            title="Create an Auction Event"
          />
        </div>
      </div>
    </section>
  );
};

export default TorontoThrive;
