import React from "react";
import cn from "classnames";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import styles from "../styles/index.module.scss";
import ArrowIcon from "../../Icons/ArrowIcon";
import DefaultButton from "../../Buttons/DefaultButton";

const IslandKidsImage =
  "https://imgix.cosmicjs.com/7f1b7d00-b55c-11ed-923b-d7bcfbd0551a-IslandKids.png";

const IslandKidsSection = () => {
  const handleTryBidddy = () => {
    if (typeof window !== "undefined") {
      window.open("https://www.jotform.com/form/230056389150049", "_blank");
    }
  };
  return (
    <section className="w-full bg-white mb-0 md:pb-32 py-16" id="icka">
      <div className="max-w-6xl mx-auto px-5">
        <div className="w-full flex-col-reverse lg:flex-row flex justify-start h-full">
          <div className="lg:w-1/3 w-full">
            <h3 className="hidden font-bold lg:block font-roboto uppercase text-bidddyPink text-lg mb-2 md:pt-24">
              Island Kids Cancer
            </h3>
            <h2 className="font-inter text-6xl font-bold text-bidddyAltGray mb-6 md:w-1/5">
              Expanding fundraising reach
            </h2>
            <a
              href="https://www.instagram.com/islandkidscancerassociation/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <h4 className="uppercase font-cubano text-bidddyGray text-2xl leading-7 mb-3">
                @islandkidscancerassociation
              </h4>
            </a>
            <div className={styles.caseStudyPoints}>
              <h5>Intro</h5>
              <p>
                Every year, Island Kids Cancer hosts an annual golf fundraising
                event that includes a live silent auction. Using Bidddy, IKCA
                ran a social auction as a new and exciting way to boost results!
              </p>
              <h5>Goals</h5>
              <ul>
                <li>Increase bid activity and raise more funds</li>
                <li>Increase event and cause awareness</li>
                <li>Increase social following and engagement</li>
                <li>Attract and engage supporters</li>
              </ul>
              <h5>Solutions</h5>
              <p>
                Bidddy helped Island Kids Cancer transition into the world of
                social auctions with a full set of solutions including:
              </p>
              <ul>
                <li>Automated auction integration for Instagram</li>
                <li>Custom branded auction page</li>
                <li>In-person + online auction support</li>
                <li>Advanced auction insights</li>
                <li>Dedicated 1-on-1 support</li>
              </ul>
            </div>
          </div>
          <div className="lg:w-2/3 w-full">
            <h3 className="block lg:hidden font-roboto uppercase text-bidddyPink font-bold text-lg mb-2 md:pt-24">
              Island Kids Cancer
            </h3>
            <Imgix
              src={IslandKidsImage}
              height={717}
              width={717}
              htmlAttributes={{
                alt: "Island Kids Cancer Association",
                src: `${IslandKidsImage}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className={cn(styles.image, "lazyload blur-up")}
            />
            <div className={styles.quote}>
              <h3>
                “We didn’t realize our potential on social until using Bidddy”
              </h3>
              <p className="mb-12">- Hope, Administrative Coordinator </p>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-row gap-6 overflow-auto mt-12 h-full py-4">
          <div className={styles.stat}>
            <h3>336%</h3>
            <h2>Increase in Engagement</h2>
          </div>
          <div className={styles.stat}>
            <h3>5%</h3>
            <h2>Increase in Followers</h2>
          </div>
          <div className={styles.stat}>
            <h3>3x</h3>
            <h2>Retained Engagement</h2>
          </div>
        </div>
        <ArrowIcon className={styles.arrowRight} />
        <div className="flex md:justify-center md:items-center flex-col">
          <h3 className="mt-12 md:text-center md:mx-auto text-bidddyPink text-2xl font-bold font-inter">
            Ready to power up your fundraising and followers?
          </h3>
          <DefaultButton
            extraClasses={styles.defaultButton}
            onClick={() => handleTryBidddy()}
            title="Create an Auction Event"
          />
        </div>
      </div>
    </section>
  );
};

export default IslandKidsSection;
