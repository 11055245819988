import React from "react";
import Footer from "../Home/internal/Footer";
import GoodVibes from "../Home/internal/GoodVibes";
import CaseStudiesHeader from "./internal/CaseStudiesHeader";
import CollabSection from "./internal/CollabSection";
import IslandKidsSection from "./internal/IslandKidsSection";
import TorontoThrive from "./internal/TorontoThrive";

const CaseStudiesContainer = () => (
  <>
    <CaseStudiesHeader />
    <IslandKidsSection />
    <TorontoThrive />
    <CollabSection />
    <GoodVibes />
    <Footer />
  </>
);

export default CaseStudiesContainer;
